<template>
  <div>
    <p class="fs-20 fw-500 border-b border-solid border-t-0 border-r-0 border-l-0 border-gray-200">
      <span
        class="txt-grey-900 inline-block border-b-4 border-solid border-t-0 border-r-0 border-l-0 border-pri-900"
      >{{ $t('HODO Credit') }}</span>
    </p>
    <div class="flex justify-between items-center">
      <div v-html="$t('fm_used_points',{points:Math.round(availablePoints.points||0)})" />
      <b-form-checkbox
        :disabled="!availablePoints.points"
        :checked="isChecked"
        class="custom-control-success"
        name="check-button"
        switch
        @change="handleUsedPoints"
      />
    </div>
  </div>
</template>

<script>
import appUtils from "../../utils/appUtils";
export default {
  name: "HodoCredit",
  props: {
    pointsData: Object,
    availablePoints: Object
  },
  data() {
    return {
      isChecked: false
    };
  },
  watch: {
    availablePoints: {
      deep: true,
      handler(data) {
        this.isChecked = false;
        this.$emit("onChange", false);
      }
    }
  },
  created() {},
  methods: {
    handleUsedPoints(isUsed) {
      this.isChecked = !this.isChecked;

      if (!isUsed || !this.availablePoints.points) {
        this.isChecked = false;
        this.$emit("onChange", false);

        return;
      }

      if (this.availablePoints.isAllPoints) {
        this.$alert(
          this.$t(
            `Số điểm của bạn không đủ để mua sản phẩm. Vui lòng chọn hình thức thanh toán để đặt hàng.`
          ),
          {
            type: "warning",
            confirmButtonText: this.$t("lbl_confirm"),
            callback: action => {
              this.isChecked = false;
              this.$emit("onChange", false);
            }
          }
        );
      } else {
        const formatPrice = appUtils.numberFormat(
          this.availablePoints.price,
          "VNĐ"
        );

        this.$confirm(
          this.$t(
            `Bạn có chắc chắn muốn sử dụng <strong style='color:#20409B' >${Math.round(
              this.availablePoints.points
            )}</strong> điểm tương đương với <strong style='color:#20409B' >${formatPrice}</strong> để đặt hàng không?`
          ),
          this.$t("Xác nhận sử dụng điểm"),
          {
            cancelButtonText: this.$t("lbl_cancel"),
            confirmButtonText: this.$t("lbl_confirm"),
            dangerouslyUseHTMLString: true
          }
        )
          .then(_ => {
            this.isChecked = true;
            this.$emit("onChange", true);
          })
          .catch(_ => {
            this.isChecked = false;
            this.$emit("onChange", false);
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>