<template>
  <b-modal
    id="modal-edit-patient-address"
    title="Thay đổi thông tin nhận hàng"
    modal-class="modal-edit-patient-address"
    ref="modal-edit-patient-address"
    @show="handleModalShow"
    @hidden="resetModal(); $emit('hidden')"
    @ok="handleOk"
    :ok-disabled="isDisabeBtn"
    size
  >
    <b-form-group label v-slot="{ ariaDescribedby }">
      <b-card-text class="mb-2 flex justify-between items-center">
        <span>Chọn thông tin nhận hàng đã lưu</span>
        <!-- <span>hoặc</span> -->
        <b-button variant="primary" v-b-modal.modal-add-new>
          <feather-icon icon="PlusIcon"></feather-icon>
          <span style="margin-left: 4px">Thêm mới</span>
        </b-button>
      </b-card-text>
      <div class="list-address scroll-bar">
        <b-form-radio-group
          id="radio-group-1"
          v-model="info_selected"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
          class="patient-info"
          v-for="item in addressList"
          :key="item.id"
        >
          <b-form-radio :value="item.id">
            <div class="w-100 ml-2 cursor-pointer" border-variant="secondary">
              <div>
                <p class="font-semibold">{{item.name}}</p>
                <p class="flex items-center gap-2">
                  <feather-icon icon="PhoneIcon"></feather-icon>
                  {{item.phone}}
                </p>
                <p class="mb-0 flex items-center gap-2">
                  <feather-icon icon="HomeIcon"></feather-icon>
                  {{item.address}}{{item.city && `, ${item.city}`}}
                </p>
              </div>
              <b-badge v-if="item.default === 2" variant="info" class="h-fit mt-1">Mặc định</b-badge>
            </div>
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </b-form-group>
    <modal-add-new-address route="confirm" @create="handleCreate"></modal-add-new-address>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ModalAddNewAddress from '../../pages/User/UserAddress/ModalAddNewAddress.vue'

export default {
  name: 'ModalEditPatientAddress',
  components: {
    ValidationProvider,
    ValidationObserver,
    ModalAddNewAddress
  },
  props: ['addressId'],
  data() {
    return {
      info_selected: null,
      options: [],
      addressList: []
    }
  },
  computed: {
    isDisabeBtn() {
      return !this.info_selected
    }
  },
  // mounted() {
  //   this.fetchAddressList()
  // },
  methods: {
    async fetchAddressList() {
      const params = {
        keyword: '',
        user_id: this.$user.id,
        sort_by: '',
        order: '',
        page_num: 1,
        page_size: 100
      }
      // this.loading = true
      await this.$rf
        .getRequest('EcomRequest')
        .getAddressListOfUser(params)
        .then(res => {
          this.addressList = res.data.data
          // this.loading = false
        })
    },
    async handleModalShow() {
      this.info_selected = this.addressId || null
      this.fetchAddressList()
    },
    async createNewAddress() {
      //
    },
    resetModal() {
      //
    },
    handleOk(e) {
      this.$nextTick(() => {
        this.$refs['modal-edit-patient-address'].hide()
      })
      e.preventDefault()
      this.$emit(
        'change',
        this.addressList?.find(elm => elm.id === this.info_selected),
        this.info_selected
      )
    },
    handleCreate(value) {
      this.fetchAddressList()
      this.info_selected = value.id
      //
    }
  }
}
</script>

<style lang="scss" scoped>
// .modal-edit-patient-address {
//   max-height: 800px;
// }
.list-address {
  max-height: 440px;
  overflow: auto;
}
.add-new {
  &:hover {
    color: #20419b;
  }
}

.patient-info {
  border-bottom: 1px solid #dbdbdb;
  padding: 12px 0;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  &:first-child {
    padding-top: 0 !important;
  }
}

::v-deep.list-address {
  .custom-control {
    width: 90%;
    .custom-control-label {
      width: 100%;
    }
  }
}
.scroll-bar {
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: rgba(241, 241, 241, 0.4);
    border-radius: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: #b9b9b9;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  // }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}
</style>
<style lang="scss">
.modal-edit-patient-address {
  .modal-dialog {
    margin: 48px auto !important;
  }
}
</style>