<template>
  <div
    class="modal fade"
    id="modalConfirmPac"
    tabindex="-1"
    aria-labelledby="modalConfirmPac"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content radius-15 p-4">
        <div class="mb-3">
          <div class="text-right flex justify-between items-center">
            <div class="fs-20 fw-600">Đồng ý chia sẻ thông tin cá nhân</div>
            <svg
              @click="showModalConfirm(false)"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer"
            >
              <path
                d="M6 18.4998L17.9998 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.9998 18.4998L6 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="fw-500">
          Với việc đồng ý gửi các thông tin và tài liệu bạn đã điền. Bạn sẽ chấp
          thuận cho phép HODO và các nhà cung cấp dịch vụ chia sẻ dữ liệu với
          các phòng thí nghiệm.
        </div>
        <br />
        <div class="fw-500">
          <b>Lưu ý:</b> Dữ liệu được chia sẻ hoàn toàn cho quá trình
          xét nghiệm được diễn ra với hiệu quả cao nhất.
        </div>
        <div class="flex justify-end mt-5 space-x-2">
          <button class="btn btn-light w-40 p-2" @click="showModalConfirm(false)">Hủy</button>
          <button @click="confirm" class="btn btn-pri w-40 p-2">Đồng ý & tiếp tục</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirmShareInformation',
  data () {
    return {}
  },
  methods: {
    confirm () {
      this.$emit('confirm')
      this.showModalConfirm(false)
    },
    showModalConfirm (show) {
      window.$('#modalConfirmPac').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

  <style lang="css" scoped>
</style>
